import React from "react";
import { css } from "@emotion/core";
import styles from "../constants/theme";
import BackgroundImage from "gatsby-background-image";

export default function Hero({ imageURL, children, ...rest }) {
  const inner = () => (
    <>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.1));
        `}
      />
      <div
        css={css`
          position: relative;
          ${styles.flexCenter()};
          flex-direction: column;
          padding: ${styles.margin.small};
          text-align: center;
        `}
      >
        {children}
      </div>
    </>
  );

  const bgStyle = css`
    height: calc(100vh - ${styles.nav.height});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: ${styles.colors.blueDark};
    color: ${styles.colors.white};
    ${styles.flexCenter()};
    flex-direction: column;
    position: relative;
  `;

  if (imageURL) {
    return (
      <BackgroundImage fluid={imageURL} css={bgStyle} {...rest}>
        {inner()}
      </BackgroundImage>
    );
  } else {
    return (
      <div css={bgStyle} {...rest}>
        {inner()}
      </div>
    );
  }
}
